/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from "../../services/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { ContainerCheckout } from "./style.js";

import Container from 'react-bootstrap/Container';

import { verifyLogin } from "./../../utils/verifyLogin";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { Link } from "react-router-dom";

import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";
import { getDevice } from "./../../utils/getDevice";

import { useCart } from './../../utils/CartContext';

import ModalAddAddress from './../../components/ModalAddAddress';
import { Grid, Typography, Autocomplete, TextField, Button, Stack } from '@mui/material';
import { AiOutlineReload } from 'react-icons/ai';
import { LoadingButton } from '@mui/lab';

const schema = yup.object().shape({
  addressSelected: yup.string().required('Selecione um endereço para entrega!'),
  paymentSelected: yup.string().required('Selecione a forma de pagamento!'),
  dispatchSelected: yup.string().required('Selecione a forma de envio!'),
  observation: yup.string().max(500, 'A observação deve ter no máximo 500 caracteres.'),
});

const Checkout = () => {

  document.title = "Atacado Militar - Finalizar Pedido"

  const { setCartLength } = useCart();

  const navigate = useNavigate();

  const [isSending, setIsSending] = useState(false);
  const [idDM001, setIdDM001] = useState(0);
  const [mailClient, setMailClient] = useState(0);
  const [cdClient, setCdClient] = useState(0);
  const [addressSelected, setAddressSelected] = useState();
  const [paymentSelected, setPaymentSelected] = useState();
  const [dispatchSelected, setDispatchSelected] = useState();
  const [addressList, setAddressList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [dispatchList, setDispatchList] = useState([]);

  const [prodListCart, setProdListCart] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [vlMinOrder, setVlMinOrder] = useState(0);
  const [handleModalAddAddress, setHandleModalAddAddress] = useState(false);

  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      addressSelected: '',
      paymentSelected: '',
      dispatchSelected: '',
      observation: '',
    },
  });

  useEffect(() => {
    const fetchClientData = async () => {
      const timeout = 5000; // 5 seconds timeout
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout')), timeout)
      );

      try {
        const lsClientPromise = new Promise((resolve) => {
          const lsClient = JSON.parse(localStorage.getItem('userDataLogin'));
          resolve(lsClient);
        });

        const lsClient = await Promise.race([lsClientPromise, timeoutPromise]);

        if (lsClient?.ID_DM001) {
          setIdDM001(lsClient.ID_DM001);
          setCdClient(lsClient.CD_CLIENTE);
          setMailClient(lsClient.DS_EMAIL);
        } else {
          throw new Error('Client data not found');
        }
      } catch (error) {
        toast.error('Erro ao buscar dados do cliente. Tente novamente mais tarde.');
        console.error(error);
        navigate(`/`);
      }
    };

    if (!verifyLogin()) {
      navigate(`/`);
    } else {
      fetchClientData();
    }
  }, []);

  useEffect(() => {
    setValue('addressSelected', addressSelected);
  }, [addressSelected, setValue]);

  useEffect(() => {
    setValue('paymentSelected', paymentSelected);
  }, [paymentSelected, setValue]);

  useEffect(() => {
    setValue('dispatchSelected', dispatchSelected);
  }, [dispatchSelected, setValue]);

  useEffect(() => {
    const fetchData = async () => {
      if (!idDM001) return;

      try {
        // Fetch orders filter
        const dataOrdersFilter = { ID_DM001: idDM001 };
        const ordersResponse = await api.post("get_orders_filter", dataOrdersFilter);
        const filteredResponseData = ordersResponse.data.filter((item) => item.IN_SITUACAO !== "3");

        const dataFilterParm = {
          DS_KEY_PARAMETRO: filteredResponseData.length < 2 ? 'VL_PEDIDO_MINIMO_1' : 'VL_PEDIDO_MINIMO_2',
        };

        // Fetch minimum order value
        const parmsResponse = await api.post("filter_parms", dataFilterParm);
        setVlMinOrder(parmsResponse.data[0]?.DS_VALOR_PARAMETRO || 0);

        // Fetch payment methods
        const paymentsResponse = await api.get("get_all_payments");
        setPaymentList(paymentsResponse.data);

        // Fetch dispatch methods
        const dispatchResponse = await api.get("get_all_dispatchs");
        setDispatchList(dispatchResponse.data);

        // Fetch addresses
        const addressResponse = await api.get(`get_all_address/${idDM001}`);
        const updatedAddresses = addressResponse.data.map((address) => ({
          ...address,
          IN_PADRAO: "N",
        }));

        const defaultAddressIndex = addressResponse.data.findIndex((address) => address.IN_PADRAO === "S");
        if (defaultAddressIndex !== -1) {
          updatedAddresses[defaultAddressIndex].IN_PADRAO = "S";
        }

        setAddressList(updatedAddresses);
        if (defaultAddressIndex !== -1) {
          setAddressSelected(updatedAddresses[defaultAddressIndex].ID_DM002);
        }
      } catch (err) {
        toast.error('Erro, tente novamente mais tarde!');
        console.error(err);
      }
    };

    fetchData();
  }, [idDM001]);

  useEffect(() => {
    if (!verifyLogin()) {
      navigate(`/`);
    }

    const prodList = JSON.parse(localStorage.getItem('productCart'));
    if (prodList) {
      setProdListCart(prodList)
    }

  }, []);

  useEffect(() => {
    api
      .get("get_all_sizes")
      .then((response) => {
        setSizeList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    api
      .get("get_all_colors")
      .then((response) => {
        setColorList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const findSizeById = (id) => {
    const size = sizeList.find((size) => size.ID_DM004 === parseInt(id));
    return size ? size.SG_TAMANHO : "";
  };

  const findColorById = (id) => {
    const color = colorList.find((color) => color.ID_DM003 === parseInt(id));
    return color ? color.NM_COR : "";
  };

  const calculateTotalPrice = (quantities, price) => {
    const totalQuantities = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
    return totalQuantities * price;
  };

  const orderCartFormatter = () => {
    const remodeledProdListCart = prodListCart.map((product) => {
      const { quantities, ...rest } = product;
      const newQuantities = Object.entries(quantities).reduce((acc, [key, value]) => {
        const [colorId, sizeId] = key.split('-');
        if (!acc[colorId]) {
          acc[colorId] = {};
        }
        if (!acc[colorId][sizeId]) {
          acc[colorId][sizeId] = {};
        }
        acc[colorId][sizeId][product.laterality] = value;
        return acc;
      }, {});
      return { ...rest, quantities: newQuantities };
    });

    const groupedProducts = remodeledProdListCart.reduce((acc, product) => {
      const { id } = product;
      if (!acc[id]) {
        acc[id] = { ...product };
      } else {
        Object.keys(product.quantities).forEach((colorId) => {
          if (!acc[id].quantities[colorId]) {
            acc[id].quantities[colorId] = { ...product.quantities[colorId] };
          } else {
            Object.keys(product.quantities[colorId]).forEach((sizeId) => {
              if (!acc[id].quantities[colorId][sizeId]) {
                acc[id].quantities[colorId][sizeId] = { ...product.quantities[colorId][sizeId] };
              } else {
                Object.keys(product.quantities[colorId][sizeId]).forEach((laterality) => {
                  if (!acc[id].quantities[colorId][sizeId][laterality]) {
                    acc[id].quantities[colorId][sizeId][laterality] = product.quantities[colorId][sizeId][laterality];
                  } else {
                    acc[id].quantities[colorId][sizeId][laterality] += product.quantities[colorId][sizeId][laterality];
                  }
                });
              }
            });
          }
        });
      }
      return acc;
    }, {});

    return Object.values(groupedProducts);
  };

  const calculateTotalQuantity = (quantities) => {
    const totalQuantities = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
    return totalQuantities;
  };

  const onSubmit = (data) => {
    const { addressSelected, paymentSelected, dispatchSelected, observation } = data;

    const DataOrder = {
      clientId: idDM001,
      cdClient: cdClient,
      addressId: addressSelected,
      mailClient: mailClient,
      DS_OBSERVACAO: observation,
      DS_FORMA_PAGAMENTO: paymentSelected,
      DS_FORMA_ENVIO: dispatchSelected,
      NM_DISPOSITIVO: getDevice(),
      products: orderCartFormatter(),
    };

    setIsSending(true);
    api.post("create_order", DataOrder)
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem('productCart');
          setCartLength(0);
          setProdListCart([]);
          navigate('/orders');
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response?.data?.message || "Erro desconhecido no servidor.");
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const grandTotal = prodListCart.reduce((acc, prod) => acc + calculateTotalPrice(prod.quantities, prod.price), 0);

  useEffect(() => {
    if (vlMinOrder > 0 && vlMinOrder > grandTotal) {
      toast.warning(`Pedido mínimo de: ${formatCurrencyBRL(vlMinOrder)}`);
      toast.warning(`Redirecionando para o carrinho...`);
      setTimeout(() => {
        navigate('/cart');
      }, 5000);
    }
  }, [vlMinOrder, grandTotal]);

  const addNewAddressOpen = useCallback(() => {
    setHandleModalAddAddress(true);
  },
    [handleModalAddAddress]
  );

  const onCloseModalAddAddress = () => {
    setHandleModalAddAddress(false);
  };

  const addNewAddress = (address) => {
    api
      .post("create_client_address", address)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            setAddressList((prevState) => [
              ...prevState,
              response.data.address,
            ]);
            setAddressSelected(response.data.address.ID_DM002);
            toast.success(response.data.message)
            setHandleModalAddAddress(false);
          } else {
            toast.warning(response.data.message)
          }
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((err) => {
        toast.error('Erro, tente novamente mais tarde!')
        console.log(err)
      });
  }

  const getAddressClient = async () => {
    try {
      const response = await api.get(`get_all_address/${idDM001}`);
      setAddressList(response.data);
      toast.success('Lista de endereços atualizada!');
    } catch (err) {
      toast.error('Erro ao atualizar lista de endereços.');
      console.error(err);
    }
  };

  return (
    <>

      <Header />
      <ContainerCheckout>
        <Container className="conShadown">
          <Typography variant="h4" component="h1" gutterBottom>
            Finalizar Pedido
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} direction={{ xs: 'column', md: 'column', lg: 'row' }}>
              <div style={{ display: 'flex', alignItems: 'center', minWidth: '200px', gap: '8px' }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={addNewAddressOpen}
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      padding: '6px 12px',
                      backgroundColor: 'black'
                    }}
                  >
                    + Novo Endereço
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={getAddressClient}
                    style={{
                      minWidth: '36px',
                      height: '36px',
                      padding: '6px 12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AiOutlineReload />
                  </Button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', minWidth: '200px', maxWidth: '500px' }}>
                  <Controller
                    name="addressSelected"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={addressList}
                        getOptionLabel={(option) => option.NM_ENDERECO || ""}
                        value={addressList.find((address) => address.ID_DM002 === field.value) || null}
                        onChange={(event, newValue) => {
                          setAddressSelected(newValue?.ID_DM002 || "");
                          field.onChange(newValue?.ID_DM002 || "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione o Endereço"
                            error={!!errors.addressSelected}
                            helperText={errors.addressSelected?.message}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', minWidth: '200px', maxWidth: '500px' }}>
                  <Controller
                    name="paymentSelected"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={paymentList}
                        getOptionLabel={(option) => option.DS_FORMA_PAGAMENTO || ""}
                        value={paymentList.find((payment) => payment.DS_FORMA_PAGAMENTO === field.value) || null}
                        onChange={(event, newValue) => {
                          setPaymentSelected(newValue?.DS_FORMA_PAGAMENTO || "");
                          field.onChange(newValue?.DS_FORMA_PAGAMENTO || "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Forma de Pagamento"
                            error={!!errors.paymentSelected}
                            helperText={errors.paymentSelected?.message}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', minWidth: '200px', maxWidth: '500px' }}>
                  <Controller
                    name="dispatchSelected"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={dispatchList}
                        getOptionLabel={(option) => option.DS_FORMA_ENVIO || ""}
                        value={dispatchList.find((dispatch) => dispatch.DS_FORMA_ENVIO === field.value) || null}
                        onChange={(event, newValue) => {
                          setDispatchSelected(newValue?.DS_FORMA_ENVIO || "");
                          field.onChange(newValue?.DS_FORMA_ENVIO || "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Forma de Envio"
                            error={!!errors.dispatchSelected}
                            helperText={errors.dispatchSelected?.message}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </div>
            </Stack>

            {addressSelected && (
              <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
                <Typography variant="h6" gutterBottom>
                  Endereço Selecionado
                </Typography>
                {addressList
                  .filter((address) => address.ID_DM002 === addressSelected)
                  .map((address) => (
                    <div key={address.ID_DM002}>
                      <Typography variant="body1">
                        <strong>Nome:</strong> {address.NM_ENDERECO}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Rua:</strong> {address.DS_LOGRADOURO}, {address.NR_PREDIO} {address.DS_COMPLEMENTO ? `(${address.DS_COMPLEMENTO})` : ''} {address.DS_REFERENCIA ? `, (${address.DS_REFERENCIA})` : ''}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Bairro:</strong> {address.NM_BAIRRO}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Cidade:</strong> {address.NM_CIDADE} - {address.NM_UF}
                      </Typography>
                      <Typography variant="body1">
                        <strong>CEP:</strong> {address.NR_CEP}
                      </Typography>
                    </div>
                  ))}
              </Paper>
            )}

            
            {prodListCart.length > 0 && (
              <>
                <TableContainer component={Paper} className="productListCheckout">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Nome do Produto</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Variações</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }} align="center">Quantidade</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }} align="right">Preço Unitário</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }} align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prodListCart.map((prod, index) => {
                        const totalPrice = calculateTotalPrice(prod.quantities, prod.price);
                        const totalQuantity = calculateTotalQuantity(prod.quantities);
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {prod.name} - Lado: {prod.laterality === 'C' ? 'Canhoto' : prod.laterality === 'D' ? 'Destro' : 'Neutro'}
                            </TableCell>
                            <TableCell>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }}>Cor</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }} align="center">Tamanho</TableCell>
                                    <TableCell sx={{ fontWeight: 600, backgroundColor: '#f5f5f5' }} align="center">Quantidade</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.entries(prod.quantities)
                                    .map(([key, value]) => {
                                      const [colorId, sizeId] = key.split('-');
                                      const color = findColorById(colorId);
                                      const size = findSizeById(sizeId);
                                      return { color, size, colorId, sizeId, value };
                                    })
                                    .sort((a, b) => a.color.localeCompare(b.color))
                                    .map(({ color, size, colorId, sizeId, value }) => (
                                      <TableRow key={`${colorId}-${sizeId}`}>
                                        <TableCell>{color}</TableCell>
                                        <TableCell align="center">{size}</TableCell>
                                        <TableCell align="center">{value}</TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                            <TableCell align="center">{totalQuantity}</TableCell>
                            <TableCell align="right">{formatCurrencyBRL(prod.price)}</TableCell>
                            <TableCell align="right" style={{fontWeight:600}}>{formatCurrencyBRL(totalPrice)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <Typography variant="subtitle1" fontWeight="bold">Observação:</Typography>
                <Controller
                  name="observation"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={4}
                      fullWidth
                      error={!!errors.observation}
                      helperText={errors.observation?.message}
                    />
                  )}
                />
                <Grid container className="footerCart" spacing={2} alignItems="center">
                  <Grid item xs={12} md={12} style={{ textAlign: 'right', fontWeight: 'bold', order: 0 }}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                        order: -1, // Ensure it appears above buttons in mobile
                      }}
                    >
                      Valor Total: {formatCurrencyBRL(grandTotal)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} style={{ textAlign: 'left', fontWeight: '600', order: 1 }}>
                    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} style={{ width: '100%' }}>
                      <Link to="/cart" style={{ textDecoration: 'none', width: '100%' }}>
                        <LoadingButton
                          variant="outlined"
                          color="primary"
                          loading={isSending}
                          fullWidth
                          style={{ fontWeight: 'bold' }}
                        >
                          Voltar
                        </LoadingButton>
                      </Link>
                      {vlMinOrder < grandTotal && (
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="success"
                          loading={isSending}
                          fullWidth
                          style={{ fontWeight: 'bold' }}
                        >
                          {isSending ? 'Enviando...' : 'Finalizar Pedido'}
                        </LoadingButton>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </Container>
      </ContainerCheckout>
      
      <ToastContainer />

      <ModalAddAddress
        modalStatus={handleModalAddAddress}
        closeModelFunction={onCloseModalAddAddress}
        addNewAddressFunction={addNewAddress}
      />
      <Footer />
    </>
  );
}

export default Checkout;
