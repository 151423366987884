import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Button } from '@mui/material';
import { toast } from 'react-toastify';
import api from "../../../services/api";

const LoginForm = ({ controllFP, setControllFP, handleLogin }) => {
  const schema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
    password: controllFP ? null : Yup.string().required('Senha é obrigatória'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (controllFP) {
      try {
        const response = await api.post("mail_recover", { DS_EMAIL: data.email });
        if (response.data.success) {
          toast.success(response.data.message);
          setControllFP(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (err) {
        toast.error('Erro, tente novamente mais tarde!');
      }
    } else {
      handleLogin(data); // Usar o handleLogin passado como prop
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="E-mail"
        variant="outlined"
        size="small"
        fullWidth
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        margin="normal"
      />
      {!controllFP && (
        <TextField
          label="Senha"
          type="password"
          variant="outlined"
          size="small"
          fullWidth
          {...register('password')}
          error={!!errors.password}
          helperText={errors.password?.message}
          margin="normal"
        />
      )}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        sx={{ backgroundColor: 'black', '&:hover': { backgroundColor: '#333' } }} // Cor de fundo preta
        style={{ marginTop: '10px' }}
      >
        {controllFP ? 'Enviar' : 'Entrar'}
      </Button>
      <Button
        variant="text"
        fullWidth
        onClick={() => setControllFP(!controllFP)}
        style={{ marginTop: '10px' }}
      >
        {controllFP ? 'Voltar' : 'Esqueci minha senha'}
      </Button>
    </form>
  );
};

export default LoginForm;
